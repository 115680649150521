<template>
    <div class="page page-composition-inclusive"  @scroll="wheelTouch">

        <!--Section one-->
        <div id="first-page" class="page-section section-first section-3">
            <VideoCompositionInclusive/>
            <div class="page-section-col block-text" :class="{'anim-slide-right-blur':AnimtxtBlur, 'd-block':showBloc}">
                <div>
                    <h1 class="page-title">{{$t('titleCompo')['0']}}
                        <br> {{$t('titleCompo')['1']}}</h1>
                </div>
            </div>

            <div class="scroll-right">
                <a href="#" class="btn btn-white btn-scroll" v-on:click="scrollToNext">  
                <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1.20101L10.8995 11.1005L1 21" stroke="#6CB0BD" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <svg width="12" height="22" viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1 1.20101L10.8995 11.1005L1 21" stroke="#6CB0BD" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                </a>
            </div>
        </div>
        <!--Section One-->

        <!--Section Image-->
        <div class="page-section section-image section-3">
            <div class="page-section-col">
                <picture class="loadTemp"> 
                    <source srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-bg-2.webp"' type="image/webp"> 
                    <img src="" :dta-src='this.$pathprod+"images/composition-inclusive/composition-inclusive-bg-2.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-bg-2-retina.jpg 2x"' alt="composition-inclusive-bg-2" width="1024" height="768">
                </picture>
            </div>
        </div>
        <!--Section Image-->

        <!--Section Geste esquisse projet-->
        <div class="page-section section-esquisse-projet section-3" id="esquisse-projet">
            <div class="page-section-col page-section-scrollable" @wheel="wheelIntersection">
                <div class="esquisse-projet-content">
                    <div class="esquisse-projet-content-col">
                        <div class="esquisse-text-item">
                            <div class="esquisse-top-info">
                                <div class="esquisse-projet-top">
                                    <h2 class="title-h2">{{$t('dataEsquisse')['0']}}</h2>
                                </div>
                                <div class="esquisse-projet-top-img">
                                    <img :src='this.$pathprod+"images/logo/logo-dgm.svg"' alt="logo-dgm" width="64" height="64">
                                    <img :src='this.$pathprod+"images/logo/logo-bb-studio-architecture.svg"' alt="logo-bb-studio-architecture" width="64" height="64">
                                </div>
                                <div class="esquisse-bottom-top">
                                    <p class="text-turquoise">{{$t('dataEsquisse')['1']}}</p>
                                </div>
                            </div>
                            <p class="esquisse-texte">
                                {{$t('dataEsquisse')['2']}}
                            </p>
                            <p class="esquisse-texte">
                                {{$t('dataEsquisse')['3']}}
                            </p>
                        </div>
                        <div class="esquisse-text-item">
                            <p class="esquisse-texte">
                                <strong>{{$t('dataEsquisse')['4']}}</strong>
                            </p>
                            <p class="esquisse-texte">
                                <strong>{{$t('dataEsquisse')['5']}}</strong>
                            </p>
                            <p class="esquisse-texte">
                                {{$t('dataEsquisse')['6']}} <strong>{{$t('dataEsquisse')['7']}}</strong> {{$t('dataEsquisse')['8']}}
                            </p>
                            <p class="esquisse-texte">
                                {{$t('dataEsquisse')['9']}}<strong>{{$t('dataEsquisse')['10']}}</strong> {{$t('dataEsquisse')['11']}}
                            </p>
                            <p class="esquisse-texte hide-under-360">
                                {{$t('dataEsquisse')['12']}} <strong>{{$t('dataEsquisse')['13']}}</strong> {{$t('dataEsquisse')['14']}}
                            </p>
                        </div>
                        <div class="esquisse-text-item">
                            <p class="esquisse-texte show-under-360">
                                {{$t('dataEsquisse')['12']}} <strong>{{$t('dataEsquisse')['13']}}</strong> {{$t('dataEsquisse')['14']}}
                            </p>
                            <p class="esquisse-texte">
                                {{$t('dataEsquisse')['15']}} <strong>{{$t('dataEsquisse')['16']}}</strong>{{$t('dataEsquisse')['17']}}
                            </p>
                            <p class="esquisse-texte hide-under-360">
                                {{$t('dataEsquisse')['18']}}
                                <strong>{{$t('dataEsquisse')['19']}}</strong>
                            </p>
                            <p class="esquisse-texte hide-under-360">
                                {{$t('dataEsquisse')['20']}}
                            </p>
                        </div>
                        <div class="esquisse-text-item">
                            <p class="esquisse-texte show-under-360">
                                {{$t('dataEsquisse')['18']}}
                                <strong>{{$t('dataEsquisse')['19']}}</strong>
                            </p>
                            <p class="esquisse-texte show-under-360">
                                {{$t('dataEsquisse')['20']}}
                            </p>
                            <p class="esquisse-texte">
                                {{$t('dataEsquisse')['21']}} <strong>{{$t('dataEsquisse')['22']}}</strong>{{$t('dataEsquisse')['23']}}
                            </p>
                            <p class="esquisse-texte hide-under-360">
                                {{$t('dataEsquisse')['24']}} <strong>{{$t('dataEsquisse')['25']}}</strong> 
                                {{$t('dataEsquisse')['26']}}
                            </p>
                            <p class="esquisse-texte hide-under-360">
                                {{$t('dataEsquisse')['27']}}
                            </p>
                            <p class="esquisse-texte hide-under-360">
                                {{$t('dataEsquisse')['28']}}
                            </p>
                        </div>
                        <div class="esquisse-text-item">
                            <p class="esquisse-texte show-under-360">
                                {{$t('dataEsquisse')['24']}} <strong>{{$t('dataEsquisse')['25']}}</strong> 
                                {{$t('dataEsquisse')['26']}}
                            </p>
                            <p class="esquisse-texte show-under-360">
                                {{$t('dataEsquisse')['27']}}
                            </p>
                            <p class="esquisse-texte show-under-360">
                                {{$t('dataEsquisse')['28']}}
                            </p>

                            <p class="esquisse-texte hide-under-360">
                                {{$t('dataEsquisse')['29']}}
                            </p>
                            <p class="esquisse-texte hide-under-360">
                                {{$t('dataEsquisse')['30']}}
                            </p>
                            <p class="esquisse-texte hide-under-360">
                                {{$t('dataEsquisse')['31']}}
                            </p>
                            <p class="esquisse-signature hide-under-360">
                                <strong>Veronique Mauer</strong>
                                {{$t('dataEsquisse')['32']}}
                            </p>
                            <p class="esquisse-signature hide-under-360">
                                <strong>Claudia Van den Berg</strong>
                                {{$t('dataEsquisse')['33']}}
                            </p>
                        </div>
                        <div class="esquisse-text-item show-under-360">
                            <p class="esquisse-texte">
                                {{$t('dataEsquisse')['29']}}
                            </p>
                            <p class="esquisse-texte">
                                {{$t('dataEsquisse')['30']}}
                            </p>
                            <p class="esquisse-texte">
                                {{$t('dataEsquisse')['31']}}
                            </p>
                        </div>
                        
                        <div class="esquisse-text-item show-under-360">
                            <p class="esquisse-signature">
                                <strong>Veronique Mauer</strong>
                                {{$t('dataEsquisse')['32']}}
                            </p>
                            <p class="esquisse-signature">
                                <strong>Claudia Van den Berg</strong>
                                {{$t('dataEsquisse')['33']}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="page-section-col">
                <div class="esquisse-img-block">
                    <figure class="bg-esquisse">
                        <picture class="loadTemp"> 
                            <source srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-silhouette.webp"' type="image/webp"> 
                            <img src="" :dta-src='this.$pathprod+"images/composition-inclusive/composition-inclusive-silhouette.png"' srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-silhouette-retina.png 2x"' alt="composition-inclusive-silhouette" width="1382" height="713"> 
                        </picture>
                    </figure>
                    <div class="esquisse-img-block-bottom">
                        <figure class="img-urbain">
                            <picture class="loadTemp"> 
                                <source srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-fenetres-urbains-2.webp"' type="image/webp"> 
                                <img src="" :dta-src='this.$pathprod+"images/composition-inclusive/composition-inclusive-fenetres-urbains-2.png"' alt="composition-inclusive-fenetres-urbains-2" width="459" height="255">
                            </picture>
                        </figure>
                        <figure class="img-coupe">    
                            <picture class="loadTemp"> 
                                <source srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-coupe.webp"' type="image/webp"> 
                                <img src="" :dta-src='this.$pathprod+"images/composition-inclusive/composition-inclusive-coupe.png"' alt="composition-inclusive-coupe patio" width="550" height="412">
                            </picture>
                        </figure>
                        <figure class="img-agora">    
                            <picture class="loadTemp"> 
                                <source srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-agora.webp"' type="image/webp"> 
                                <img src="" :dta-src='this.$pathprod+"images/composition-inclusive/composition-inclusive-agora.png"' alt="composition-inclusive-agora" width="615" height="310">
                            </picture>
                        </figure>
                    </div>
                </div>
            </div>
        </div>
        <!--Section Geste esquisse projet-->

        <!--Section Espaces rassembleurs-->
        <div class="page-section section-espaces-rassembleurs" id="espace-rassembleurs">
            <div class="page-section-col">
                <div class="dynamique-images-block">
                    <div class="dynamique-images-flou dynamic-images showBefore">
                        <picture class="loadTemp"> 
                            <source srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-agora.webp"' type="image/webp"> 
                            <img src="" :dta-src='this.$pathprod+"images/composition-inclusive/composition-inclusive-Hall-Lobby-contrast.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-Hall-Lobby-contrast-retina.jpg 2x"' alt="composition-inclusive-Hall-Lobby-contrast" width="1024" height="768"> 
                        </picture>
                        <div class="block-turquoise-dynamique">
                            <p class="block-gold-text">{{$t('titleES')}}</p>
                        </div>
                    </div>

                    <div class="dynamique-images-full dynamic-images">
                        <picture class="loadTemp"> 
                            <source srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-agora.webp"' type="image/webp"> 
                            <img src="" :dta-src='this.$pathprod+"images/composition-inclusive/composition-inclusive-Hall-Lobby-6.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-Hall-Lobby-6-retina.jpg 2x"' alt="composition-inclusive-Hall-Lobby-6" width="1024" height="768"> 
                        </picture>
                    </div>

                    <div class="block-key-number" :class="{'increment': incrementE}">
                        <div class="block-turquoise-number">
                            <picture class="loadTemp"> 
                                <source srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-space-img-1.webp"' type="image/webp"> 
                                <img src="" :dta-src='this.$pathprod+"images/composition-inclusive/composition-inclusive-space-img-1.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-space-img-1-retina.jpg 2x"' alt="composition-inclusive-space-img-1" class="espace-img-1" width="190" height="387">
                            </picture>
                            <picture class="loadTemp"> 
                                <source srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-space-img-2.webp"' type="image/webp"> 
                                <img src="" :dta-src='this.$pathprod+"images/composition-inclusive/composition-inclusive-space-img-2.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-space-img-2-retina.jpg 2x"' alt="composition-inclusive-space-img-2" class="espace-img-2" width="400" height="230">
                            </picture>
                            <picture class="loadTemp"> 
                                <source srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-space-img-3.webp"' type="image/webp"> 
                                <img src="" :dta-src='this.$pathprod+"images/composition-inclusive/composition-inclusive-space-img-3.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-space-img-3-retina.jpg 2x"' alt="composition-inclusive-space-img-3" class="espace-img-3" width="400" height="230">
                            </picture>
                            <div class="block-turquoise-number-row">
                                <div class="block-turquoise-number-col">
                                    <div class="list-item">
                                        <h4 class="title-h4-white">{{$t('subtitleES')}}</h4>
                                    </div>
                                    <div class="key-number-list">
                                        <div class="key-number-item">
                                            <p class="key-number white">{{$t('dataChiffreKeyES')['0']}} 
                                                <!-- <span class="number-to-animate" data-number="5000">5 000</span>  -->
                                                <number
                                                    animationPaused
                                                    ref="number1"
                                                    :from="0"
                                                    :to="5000"
                                                    :format="theFormat"
                                                    :duration="1"
                                                    easing="Power1.easeOut"/> 
                                                m<span class="m2">2</span></p>
                                            <p class="key-desc">{{$t('dataChiffreKeyES')['1']}}</p>
                                        </div>

                                        <div class="key-number-item">
                                            <p class="key-number white">
                                                <!-- <span class="number-to-animate" data-number="780">780</span>  -->
                                            <number
                                                animationPaused
                                                ref="number2"
                                                :from="0"
                                                :to="780"
                                                :format="theFormat"
                                                :duration="1"
                                                easing="Power1.easeOut"/> 
                                            m<span class="m2">2</span></p>
                                            <p class="key-desc">{{$t('dataChiffreKeyES')['2']}}</p>
                                        </div>

                                        <div class="key-number-item">
                                            <p class="key-number white">
                                                <!-- <span class="number-to-animate" data-number="7200">7 200</span>  -->
                                            <number
                                                animationPaused
                                                ref="number3"
                                                :from="0"
                                                :to="7200"
                                                :format="theFormat"
                                                :duration="1"
                                                easing="Power1.easeOut"/> 
                                            m<span class="m2">2</span></p>
                                            <p class="key-desc">{{$t('dataChiffreKeyES')['3']}}</p>
                                        </div>

                                        <div class="key-number-item">
                                            <p class="key-number white">
                                                <!-- <span class="number-to-animate" data-number="310">310</span>  -->
                                             <number
                                                animationPaused
                                                ref="number4"
                                                :from="0"
                                                :to="310"
                                                :format="theFormat"
                                                :duration="1"
                                                easing="Power1.easeOut"/> 
                                            m<span class="m2">2</span></p>
                                            <p class="key-desc">{{$t('dataChiffreKeyES')['4']}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="block-turquoise-number-col">
                                    <div class="block-col-content">
                                        <div class="list-item">
                                            <h4 class="title-h4-black">{{$t('dataChiffreContent')['0']}}</h4>
                                            <ul class="list-arrow">
                                                <li>{{$t('dataChiffreContent')['1']}} </li>
                                                <li>{{$t('dataChiffreContent')['2']}}</li>
                                                <li>{{$t('dataChiffreContent')['3']}}</li>
                                                <li>{{$t('dataChiffreContent')['4']}}</li>
                                                <li>{{$t('dataChiffreContent')['5']}}</li>
                                                <li>{{$t('dataChiffreContent')['6']}}</li>
                                            </ul>
                                        </div>
                                        <div class="list-item">
                                            <h4 class="title-h4-black">{{$t('dataChiffreContent')['7']}}</h4>
                                            <ul class="list-arrow">  
                                                <li><strong>{{$t('dataChiffreContent')['8']}}</strong> {{$t('dataChiffreContent')['9']}} </li>
                                                <li><strong>{{$t('dataChiffreContent')['10']}}</strong> {{$t('dataChiffreContent')['11']}} <br> {{$t('dataChiffreContent')['12']}}</li>
                                                <li><strong>{{$t('dataChiffreContent')['13']}}</strong> {{$t('dataChiffreContent')['14']}} <br> {{$t('dataChiffreContent')['15']}}</li>
                                            </ul>
                                        </div>
                                        <div class="list-item hide-mobile">
                                            <h4 class="title-h4-black">{{$t('dataChiffreContent')['16']}} m<span class="m2-custom-txt subtitle-m2">2</span></h4>
                                            <ul class="list-arrow">
                                                <li>{{$t('dataChiffreContent')['17']}} m<span class="m2-custom-txt">2</span></li>
                                                <li>{{$t('dataChiffreContent')['18']}} m</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="block-turquoise-number-col">
                                    <div class="block-col-content">
                                        <div class="list-item show-mobile">
                                            <h4 class="title-h4-black">{{$t('dataChiffreContent')['16']}} m<span class="m2-custom-txt subtitle-m2">2</span></h4>
                                            <ul class="list-arrow">
                                                <li>{{$t('dataChiffreContent')['17']}} m<span class="m2-custom-txt">2</span></li>
                                                <li>{{$t('dataChiffreContent')['18']}} m</li>
                                            </ul>
                                        </div>
                                        <div class="list-item">
                                            <h4 class="title-h4-black">{{$t('dataChiffreContent')['19']}} m<span class="m2-custom-txt subtitle-m2">2</span></h4>
                                            <ul class="list-arrow">
                                                <li>{{$t('dataChiffreContent')['20']}} (250 m<span class="m2-custom-txt">2</span>) de 140 places</li>
                                                <li>{{$t('dataChiffreContent')['21']}} (800 m<span class="m2-custom-txt">2</span>)</li>
                                                <li>{{$t('dataChiffreContent')['22']}}</li>
                                                <li>{{$t('dataChiffreContent')['23']}}</li>
                                            </ul>
                                        </div>
                                        <div class="list-item hide-mobile">
                                            <h4 class="title-h4-black">{{$t('dataChiffreContent')['25']}}</h4>
                                            <ul class="list-arrow">
                                                <li>{{$t('dataChiffreContent')['26']}}</li>
                                                <li>{{$t('dataChiffreContent')['27']}}</li>
                                                <li>{{$t('dataChiffreContent')['28']}}</li>
                                                <li>{{$t('dataChiffreContent')['29']}}</li>
                                                <li>{{$t('dataChiffreContent')['30']}}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="block-turquoise-number-col show-mobile">
                                    <div class="block-col-content">
                                        <div class="list-item">
                                            <h4 class="title-h4-black">{{$t('dataChiffreContent')['25']}}</h4>
                                            <ul class="list-arrow">
                                                <li>{{$t('dataChiffreContent')['26']}}</li>
                                                <li>{{$t('dataChiffreContent')['27']}}</li>
                                                <li>{{$t('dataChiffreContent')['28']}}</li>
                                                <li>{{$t('dataChiffreContent')['29']}}</li>
                                                <li>{{$t('dataChiffreContent')['30']}}</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--Section Espaces rassembleurs-->

        <!--Section brulerie-->
        <div class="page-section section-corner section-3">
            <div class="page-section-col">
                <div class="block-image-zoom">
                    <div class="block-img block-image-transparent">
                        <picture class="loadTemp"> 
                            <source srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-croquis-echoppe-contrast.webp"' type="image/webp">  
                            <img src="" :dta-src='this.$pathprod+"images/composition-inclusive/composition-inclusive-croquis-echoppe-contrast.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-croquis-echoppe-contrast-retina.jpg 2x"' alt="composition-inclusive-croquis-echoppe" class="img-desk" width="1024" height="768">
                        </picture>
                        <picture class="loadTemp"> 
                            <source srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-croquis-echoppe-contrast-mobile1.webp"' type="image/webp"> 
                            <img src="" :dta-src='this.$pathprod+"images/composition-inclusive/composition-inclusive-croquis-echoppe-contrast-mobile1.jpg"' alt="composition-inclusive-croquis-echoppe" class="img-mobile" width="1024" height="768">
                        </picture>
                    </div>
                    <div class="block-img block-image-full">
                        <picture class="loadTemp"> 
                            <source srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-croquis-echoppe.webp"' type="image/webp"> 
                            <img src="" :dta-src='this.$pathprod+"images/composition-inclusive/composition-inclusive-croquis-echoppe.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-croquis-echoppe-retina.jpg 2x"' alt="composition-inclusive-croquis-echoppe" width="1024" height="768">
                        </picture>
                    </div>
                </div>
            </div>
        </div>
        <!--Section brulerie-->

        <!--Section carousel 1-->
        <div class="page-section section-carousel section-3">
            <div class="page-section-col">
                <hooper class="slider-block" :settings="hooperSettings"> 
                    <slide class="slider-item" v-for="carousel in carousel1" :key="carousel.id">
                        <picture> 
                            <source :srcset="carousel.srcsetWebp" type="image/webp"> 
                            <img  :src="carousel.src" :srcset="carousel.srcset" :alt="carousel.alt" @click="openLightbox(carousel.id, lightbox1)">
                        </picture>
                    </slide>
                    <hooper-pagination slot="hooper-addons"></hooper-pagination>
                </hooper>
            </div>
        </div>
        <!--Section carousel 1-->

        <!--Section Rhapsody services-->
        <div class="page-section section-rhapsody-services section-3">
            <div class="page-section-col">
                <div class="rhapsody-block">
                    <div class="rhapsody-block-top">
                        <h2 class="title-h2">{{$t('titleRhapsodySevices')}}</h2>
                        <picture> 
                            <source :srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-bb-studio-architecture-logo.webp"' type="image/webp"> 
                            <img  class="rhapsody-logo" :src='this.$pathprod+"images/composition-inclusive/composition-inclusive-bb-studio-architecture-logo.jpg"' alt="entry-harmony-bb-studio-architecture-logo" width="64" height="64">
                        </picture>
                    </div>
                    <div class="rhapsody-block-bottom">
                        <div class="rhapsody-bottom-col">
                            <div class="rhapsody-bottom-col-top">
                                <h2 class="title-h2">{{$t('titleRhapsodySevices')}}</h2>
                                <picture> 
                                    <source :srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-bb-studio-architecture-logo.webp"' type="image/webp"> 
                                    <img  class="rhapsody-logo" :src='this.$pathprod+"images/composition-inclusive/composition-inclusive-bb-studio-architecture-logo.jpg"' alt="entry-harmony-bb-studio-architecture-logo" width="64" height="64">
                                </picture>
                            </div>
                            <div class="rhapsody-bottom-col-bottom">
                                <div class="rhapsody-bottom-col-left">
                                    <img class="quote-before" :src='this.$pathprod+"images/composition-inclusive/quote-before.svg"' alt="quote-before" width="39" height="25">
                                </div>
                                <div class="rhapsody-bottom-col-right">
                                    <h4 class="title-h4">{{$t('dataRhapsodyServices')['0']}}</h4>
                                    <p class="rhapsody-text">
                                        {{$t('dataRhapsodyServices')['1']}}
                                        <img class="quote-after" :src='this.$pathprod+"images/composition-inclusive/quote-after.svg"' alt="quote-after"  width="17" height="11">
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="rhapsody-bottom-col-bottom">
                            <div class="rhapsody-bottom-col-left">
                                <img class="quote-before" :src='this.$pathprod+"images/composition-inclusive/quote-before.svg"' alt="quote-before" width="39" height="25">
                            </div>
                            <div class="rhapsody-bottom-col-right">
                                <h4 class="title-h4">{{$t('dataRhapsodyServices')['2']}}</h4>
                                <p class="rhapsody-text">
                                    {{$t('dataRhapsodyServices')['3']}}<img class="quote-after" :src='this.$pathprod+"images/composition-inclusive/quote-after.svg"' alt="quote-after"  width="17" height="11">
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--Section Rhapsody services-->
       
        <!--Section La Plaza-->
        <div class="page-section section-plaza section-3">
            <div class="page-section-col">
                <div class="block-image-zoom">
                    <div class="block-img block-image-transparent">
                        <picture class="loadTemp"> 
                            <source srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-croquis-plaza-contrast.webp"' type="image/webp"> 
                            <img src="" :dta-src='this.$pathprod+"images/composition-inclusive/composition-inclusive-croquis-plaza-contrast.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-croquis-plaza-contrast-retina.jpg 2x"' alt="composition-inclusive-croquis-plaza-contrast" class="img-desk"  width="1920" height="937">
                        </picture>
                        <picture class="loadTemp"> 
                            <source srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-croquis-plaza-contrast-mobile1.webp"' type="image/webp">
                            <img src="" :dta-src='this.$pathprod+"images/composition-inclusive/composition-inclusive-croquis-plaza-contrast-mobile1.jpg"' alt="composition-inclusive-croquis-plaza-contrast" class="img-mobile"  width="1024" height="768">
                        </picture>
                    </div>
                    <div class="block-img block-image-full">
                        <picture class="loadTemp"> 
                            <source srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-croquis-plaza.webp"' type="image/webp">
                            <img src="" :dta-src='this.$pathprod+"images/composition-inclusive/composition-inclusive-croquis-plaza.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-croquis-plaza-retina.jpg 2x"' alt="composition-inclusive-croquis-plaza" width="1024" height="768">
                        </picture>
                    </div>
                </div>
            </div>
        </div>
        <!--Section La Plaza-->

         <!--Section destination-->
        <div class="page-section section-destination section-3">
            <div class="page-section-col">
                <div class="block-destination">
                    <div class="block-destination-bottom">
                        <div class="block-bottom-col">
                            <div class="dest-item">
                                <h3 class="block-dest-title">{{$t('titleDestinationR')}}</h3>
                                <h4 class="title-h4-semi">{{$t('dataDestinationR')['0']}} <br class="br-mobile">
                                    {{$t('dataDestinationR')['1']}}
                                </h4>
                            </div>
                            <div class="dest-item">
                                <h4 class="title-h4-semi">{{$t('dataDestinationR')['2']}}</h4>
                                <p>
                                    {{$t('dataDestinationR')['3']}}
                                </p>
                            </div>
                            <div class="dest-item">
                                <h4 class="title-h4-semi">{{$t('dataDestinationR')['4']}} <span class="span-block">{{$t('dataDestinationR')['5']}}</span></h4>
                                <p>
                                    {{$t('dataDestinationR')['6']}} 
                                </p>
                            </div>
                            <div class="dest-item">
                                <h4 class="title-h4-semi">{{$t('dataDestinationR')['7']}} <span class="span-block">{{$t('dataDestinationR')['8']}}</span></h4>
                                <p>
                                    {{$t('dataDestinationR')['9']}}
                                </p>
                            </div>
                            <div class="dest-item">
                                <h4 class="title-h4-semi">{{$t('dataDestinationR')['10']}} <span class="span-block">{{$t('dataDestinationR')['11']}}</span></h4>
                                <p>
                                    {{$t('dataDestinationR')['12']}}
                                </p>
                            </div>
                            <div class="dest-item">
                                <h4 class="title-h4-semi">{{$t('dataDestinationR')['13']}}</h4>
                                <p>
                                    {{$t('dataDestinationR')['14']}}
                                </p>
                            </div>
                            <div class="dest-item">
                                <h4 class="title-h4-semi">{{$t('dataDestinationR')['15']}}</h4>
                                <p>
                                    {{$t('dataDestinationR')['16']}}
                                </p>
                            </div>
                            <div class="dest-item">
                                <h4 class="title-h4-semi">{{$t('dataDestinationR')['17']}}</h4>
                                <p>
                                    {{$t('dataDestinationR')['18']}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--Section destination-->
     
        <!-- Section Ongle RDC Ultra vivant & mezzanie -->
        <div class="page-section section-onglet-rdc section-3">
            <div class="page-section-col">
                <div class="section-tab-content">
                    <div class="tab-details">
                        <div class="tab-content">
                            <div class="tab-block-title">
                                <h3 class="tab-title">{{$t('dataOnglet')}}</h3>
                            </div>
                            <div class="tab-img">    
                                <picture> 
                                    <img :src="$t('imgOngletDeskJpg')" alt="composition-inclusive-rdc-ultra-vivant" class="tab-img-tablet" width="1920" height="937">
                                    <img :src="$t('imgOngletMobJpg')" alt="composition-inclusive-rdc-ultra-vivant-mobile" class="tab-img-mobile" width="1024" height="768">
                                </picture>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Section Ongle RDC Ultra vivant & mezzanie -->

        <!--Section Espace pollinisateur-->
        <div class="page-section section-espace-pollinisateur" id="pollinisateur">
            <div class="page-section-col">
                <div class="dynamique-images-block">
                    <div class="dynamique-images-flou dynamic-images">
                        <picture class="loadTemp"> 
                            <source srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-box-contrast.webp"' type="image/webp"> 
                            <img src="" :dta-src='this.$pathprod+"images/composition-inclusive/composition-inclusive-box-contrast.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-box-contrast-retina.jpg 2x"' alt="composition-inclusive-box-contrast" width="1024" height="768">
                        </picture>
                        <div class="block-turquoise-dynamique">
                            <p class="block-gold-text">{{$t('titleEspaceP')}}</p>
                        </div>
                    </div>

                    <div class="dynamique-images-full dynamic-images">
                        <picture class="loadTemp"> 
                            <source srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-box.webp"' type="image/webp"> 
                            <img src="" :dta-src='this.$pathprod+"images/composition-inclusive/composition-inclusive-box.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-box-retina.jpg 2x"' alt="composition-inclusive-box" width="1024" height="768">
                        </picture>
                    </div>

                    <div class="block-key-number" :class="{'increment': incrementE}">
                        <div class="block-turquoise-number">
                            <!-- <figure class="local-img"><img :src='this.$pathprod+"images/composition-inclusive/composition-inclusive-box-img-1.jpg" alt="composition-inclusive-box-img-1"></figure> -->
                            <div class="block-turquoise-top hide-mobile">
                                <h4 class="title-h4-white">{{$t('dataChiffreKeEP')['0']}}<br> {{$t('dataChiffreKeEP')['1']}}</h4>
                            </div>
                            <div class="block-turquoise-bottom">
                                <div class="block-turquoise-number-col">
                                    <h4 class="title-h4-white show-mobile">{{$t('dataChiffreKeEP')['0']}}<br> {{$t('dataChiffreKeEP')['1']}}</h4>
                                    <div class="key-number-list">
                                        <div class="key-number-item hide-mobile">
                                            <p class="key-number white">{{$t('dataChiffreKeEP')['2']}}</p>
                                        </div>
                                        <div class="key-number-grid">
                                            <div class="key-number-item key-nb show-mobile">
                                                <p class="key-number white">{{$t('dataChiffreKeEP')['2']}}</p>
                                            </div>
                                            <div class="key-number-item key-nb">
                                                <p class="key-desc">{{$t('dataChiffreKeEP')['3']}}</p>
                                                <p class="key-number white">
                                                    <!-- <span class="number-to-animate" data-number="4900">4 900</span> -->
                                                    <number
                                                        animationPaused
                                                        ref="number5"
                                                        :from="0"
                                                        :to="4500"
                                                        :format="theFormat"
                                                        :duration="1"
                                                        easing="Power1.easeOut"/> 
                                                    <br>{{$t('dataChiffreKeEP')['4']}} 
                                                    <!-- <span class="number-to-animate" data-number="5900">5 900</span>  -->
                                                    <number
                                                        animationPaused
                                                        ref="number6"
                                                        :from="0"
                                                        :to="5500"
                                                        :format="theFormat"
                                                        :duration="1"
                                                        easing="Power1.easeOut"/> 
                                                    m<span class="m2">2</span></p>
                                                <p class="key-desc">{{$t('dataChiffreKeEP')['5']}}</p>
                                            </div>
                                            
                                            <div class="key-number-item hide-mobile key-nb-2">
                                                <p class="key-number white">
                                                    <!-- <span class="number-to-animate" data-number="1,35">1,35</span>  -->
                                                    <number
                                                        animationPaused
                                                        ref="number7"
                                                        :from="0"
                                                        :to="1.35"
                                                        :format="theFormatDecimal"
                                                        :duration="1"
                                                        easing="Power1.easeOut"/>     
                                                m</p>
                                                <p class="key-desc">{{$t('dataChiffreKeEP')['6']}}</p>
                                            </div>

                                            <div class="key-number-item key-nb key-nb-3">
                                                <p class="key-number white">
                                                    <!-- <span class="number-to-animate" data-number="2,70">2,70</span> -->
                                                <number
                                                    animationPaused
                                                    ref="number8"
                                                    :from="0"
                                                    :to="2.70"
                                                    :format="theFormatDecimal"
                                                    :duration="2"
                                                    easing="Power1.easeOut"/>  
                                                m</p>
                                                <p class="key-desc">{{$t('dataChiffreKeEP')['7']}}</p>
                                            </div>

                                            <div class="key-number-item key-nb">
                                                <div class="show-mobile key-number-item-mobile">
                                                    <p class="key-number white">
                                                        <!-- <span class="number-to-animate" data-number="1,35">1,35</span>  -->
                                                    <number
                                                        animationPaused
                                                        ref="number9"
                                                        :from="0"
                                                        :to="1.35"
                                                        :format="theFormatDecimal"
                                                        :duration="2"
                                                        easing="Power1.easeOut"/>  
                                                    m</p>
                                                    <p class="key-desc">{{$t('dataChiffreKeEP')['6']}}</p>
                                                </div>
                                                <p class="key-number white">
                                                    <!-- <span class="number-to-animate" data-number="64">64</span>  -->
                                                    <number
                                                        animationPaused
                                                        ref="number10"
                                                        :from="0"
                                                        :to="64"
                                                        :format="theFormat"
                                                        :duration="2"
                                                        easing="Power1.easeOut"/> 
                                                %</p>
                                                <p class="key-desc">{{$t('dataChiffreKeEP')['8']}}</p>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div class="block-turquoise-number-col">
                                    <div class="block-col-content">
                                        <!--    TABLEAU VIVRE HARMONY    -->
                                        <!--____________________________ -->
                                        <table class="table-dynamic">
                                            <thead>
                                                <tr>
                                                    <th class="table-title"><span>{{$t('dataTableEP')['1']}}</span></th>
                                                    <th class="table-title tar"><span>{{$t('dataTableEP')['2']}} {{$t('dataTableEP')['3']}}</span></th>
                                                    <th class="table-title tar"><span>{{$t('dataTableEP')['4']}} {{$t('dataTableEP')['5']}} {{$t('dataTableEP')['6']}}</span></th>
                                                    <th class="table-title tar"><span>{{$t('dataTableEP')['7']}}</span></th>
                                                    <th class="table-title tar"><span>{{$t('dataTableEP')['8']}} {{$t('dataTableEP')['9']}}</span></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(lignTable, index) in tableVivre" :key="index">
                                                    <td>
                                                        <div>{{lignTable.sub}}</div>
                                                        <div class="txt-right">{{lignTable.subNumber}} <span v-if="lignTable.subNumber">m<span class="m2-custom-txt">2</span></span></div>
                                                    </td>
                                                    <td><div class="tab-item">{{lignTable.pers}} <span v-if="lignTable.pers">pers.</span></div></td>
                                                    <td><div class="tab-item">{{lignTable.service}} <span v-if="lignTable.service">m<span class="m2-custom-txt">2</span></span></div></td>
                                                    <td><div class="tab-item">{{lignTable.subl}} <span v-if="lignTable.subl">m<span class="m2-custom-txt">2</span></span></div></td>
                                                    <td><div class="tab-item">{{lignTable.surfaces}} <span v-if="lignTable.surfaces">m<span class="m2-custom-txt">2</span></span></div></td>
                                                </tr>
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td>
                                                        <div>
                                                            <div>TOTAL</div>
                                                            <div class="txt-right">35 433 m<span class="m2">2</span></div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            <div class="first-row">empty</div>
                                                            <div>3 796 pers</div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div>  
                                                            <div class="first-row">empty</div>
                                                            <div>7 995 m<span class="m2">2</span></div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            <div class="first-row">empty</div>
                                                            <div>43 428 m<span class="m2">2</span></div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="txt-right">
                                                            <div class="first-row">empty</div>
                                                            <div>7 213 m<span class="m2">2</span></div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                                <div class="block-turquoise-number-col col-custom">
                                    <p class="indice-text indice-custom">{{$t('dataTableEP')['11']}}</p>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
        <!--Section Espace pollinisateur-->

        <!--Section carousel 2-->
        <div class="page-section section-carousel section-3">
            <div class="page-section-col">
                <hooper class="slider-block" :settings="hooperSettings"> 
                    <slide class="slider-item" v-for="carousel in carousel2" :key="carousel.id">
                        <picture> 
                            <source :srcset="carousel.srcsetWebp" type="image/webp"> 
                            <img  :src="carousel.src" :srcset="carousel.srcset" :alt="carousel.alt" @click="openLightbox2(carousel.id, lightbox2)">
                        </picture>
                    </slide>
                    <hooper-pagination slot="hooper-addons"></hooper-pagination>
                </hooper>
            </div>
        </div>
        <!--Section carousel 2-->

        <!-- Section poste résident -->
        <div class="page-section section-poste-resident section-3">
            <div class="page-section-col">
                <div class="block-amenagement-dynamique">
                    <div class="block-top-amenagement">
                        <div class="block-top-title">
                            <h4 class="title-h2 anim-txt-focus">
                                <div>{{$t('titleAmenagementDynamique')['0']}}</div>
                                <div class="color-turquoise">{{$t('titleAmenagementDynamique')['1']}}</div>
                            </h4> 
                        </div>
                        <div class="block-title-r4">
                            <span class="block-r4-text">{{$t('rAmenagement')}}</span>
                        </div>
                        <div class="block-top-image">
                            <picture class="loadTemp"> 
                                <source srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-poste-resident2.webp"' type="image/webp">
                                <img src="" :dta-src='this.$pathprod+"images/composition-inclusive/composition-inclusive-poste-resident2.png"' alt="composition-inclusive-poste-resident" width="920" height="581">
                            </picture>
                        </div>
                    </div>
                    <div class="block-bottom-amenagement">
                        <div class="block-bottom-col">
                            <ul class="list-arrow list-black">
                                <li>{{$t('dataAmenagementDynamique')['0']}} <strong>5 445 m<span class="m2-custom-txt">2</span> *</strong></li>
                                <li>{{$t('dataAmenagementDynamique')['1']}} <strong>5 170 m<span class="m2-custom-txt">2</span></strong></li>
                                <li>{{$t('dataAmenagementDynamique')['2']}} <strong>90%</strong></li>
                                <li>{{$t('dataAmenagementDynamique')['3']}} <strong>{{$t('dataAmenagementDynamique')['4']}}</strong></li>
                                <li><strong>{{$t('dataAmenagementDynamique')['5']}}</strong> <br>{{$t('dataAmenagementDynamique')['6']}}</li>
                                <li>{{$t('dataAmenagementDynamique')['7']}} <br><strong>8,9 m<span class="m2-custom-txt">2</span></strong></li>
                                <li>{{$t('dataAmenagementDynamique')['8']}} <strong>7,4 m<span class="m2-custom-txt">2</span></strong></li>
                                <li>{{$t('dataAmenagementDynamique')['9']}} <strong>1,35 m</strong></li>
                                <li>{{$t('dataAmenagementDynamique')['10']}} <strong>19 m</strong></li>
                                <li>{{$t('dataAmenagementDynamique')['11']}} <strong>2,70 m</strong></li>
                            </ul>
                            <p class="indice-text">{{$t('dataAmenagementDynamique')["12"]}} </p>
                        </div>
                        <div class="block-bottom-col">
                            <picture class="loadTemp"> 
                                <source srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-poste-resident2.webp"' type="image/webp">
                                <img src="" :dta-src='this.$pathprod+"images/composition-inclusive/composition-inclusive-poste-resident2.png"' alt="composition-inclusive-poste-resident" width="920" height="581">
                            </picture>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Section poste résident -->

        <!--Section Roof ToP-->
        <div class="page-section section-rooftop " id="roof-top">
            <div class="page-section-col">
                <div class="dynamique-images-block">
                    <div class="dynamique-images-flou dynamic-images">
                        <picture class="loadTemp"> 
                            <source srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-rooftop-bg-1.webp"' type="image/webp">
                            <img src="" :dta-src='this.$pathprod+"images/composition-inclusive/composition-inclusive-rooftop-bg-1.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-rooftop-bg-1-retina.jpg 2x"' alt="composition-inclusive-rooftop-bg-1" width="1920" height="937">
                        </picture>
                        <div class="block-turquoise-dynamique">
                            <p class="block-gold-text">{{$t('titleRooftop')}}</p>
                        </div>
                    </div>

                    <div class="dynamique-images-full dynamic-images">
                        <picture class="loadTemp"> 
                            <source srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-rooftop-bg-2.webp"' type="image/webp">
                            <img src="" :dta-src='this.$pathprod+"images/composition-inclusive/composition-inclusive-rooftop-bg-2.jpg"' srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-rooftop-bg-2-retina.jpg 2x"' alt="composition-inclusive-rooftop-bg-2" width="1920" height="937">
                        </picture>
                    </div>

                    <div class="block-key-number" :class="{'increment': incrementE}">
                        <div class="block-turquoise-number">
                            <div class="block-turquoise-image">
                                <picture class="loadTemp"> 
                                    <source srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-rooftop-bg-3.webp"' type="image/webp">
                                    <img src="" :dta-src='this.$pathprod+"images/composition-inclusive/composition-inclusive-rooftop-bg-3.png"' srcset="" :dta-srcset='this.$pathprod+"images/composition-inclusive/composition-inclusive-rooftop-bg-3-retina.png 2x"' alt="composition-inclusive-rooftop-bg-3" class="img-plan" width="820" height="518">
                                </picture>
                            </div>
                            <div class="block-gold-item">
                                <h4 class="title-h4-white">{{$t('dataRooftop')['0']}}</h4>
                            </div>
                            <div class="block-gold-item">
                                <h4 class="title-h4-white">7 200 mm<span class="m2-custom-txt subtitle-m2">2</span> {{$t('dataRooftop')['1']}} mm<span class="m2-custom-txt subtitle-m2">2</span> {{$t('dataRooftop')['2']}} </h4>
                            </div>
                            <div class="block-gold-item">
                                <ul class="list-arrow">
                                    <li>200 mm<span class="m2-custom-txt">2</span>{{$t('dataChiffreKeyRoofTop')['0']}}</li>
                                    <li>600 mm<span class="m2-custom-txt">2</span> {{$t('dataChiffreKeyRoofTop')['1']}}</li>
                                    <li>340 m {{$t('dataChiffreKeyRoofTop')['2']}}</li>
                                    <li>2 300 mm<span class="m2-custom-txt">2</span> {{$t('dataChiffreKeyRoofTop')['3']}}</li>
                                    <li>{{$t('dataChiffreKeyRoofTop')['4']}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--Section Roof ToP-->

        <!--Section carousel 3-->
        <div class="page-section section-carousel section-3">
            <div class="page-section-col">

                <hooper class="slider-block" :settings="hooperSettings"> 
                    <slide class="slider-item" v-for="carousel in carousel3" :key="carousel.id">
                        <picture> 
                            <source :srcset="carousel.srcsetWebp" type="image/webp">
                            <img  :src="carousel.src" :srcset="carousel.srcset" :alt="carousel.alt" @click="openLightbox3(carousel.id, lightbox3)">
                        </picture>
                    </slide>
                    <hooper-pagination slot="hooper-addons"></hooper-pagination>
                </hooper>
            </div>
        </div>
        <!--Section carousel 3-->

        <!--LightBox Carousel 1-->
        <div class="lightbox lightbox-1" :class="{'show': showLightbox}" v-show="showLightbox">
            <a href="#" class="btn btn-white btn-close-lightbox" @click="closeLightbox">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.635934 13.364L13.3639 0.636039M0.635934 0.636039L13.3639 13.364" stroke="#F3C678" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </a>
            <div class="lightbox-slider">
                <div class="lightbox-item" v-for="carousel in carousel1" :key="carousel.id" :data-item="carousel.id">
                    <picture> 
                        <source :srcset="carousel.srcsetWebp" type="image/webp">
                        <img  :src="carousel.src" :srcset="carousel.srcset" :alt="carousel.alt" width="1920" height="937">
                    </picture>
                    <div class="legend-block">
                        <p class="legend-text">
                            <strong>{{$t('DataLightboxLegend1')[carousel.id-1]}}</strong>
                            {{$t('DataLightboxLegendSubNumber1')[carousel.id-1]}}<sup>{{$t('DataLightboxLegendSubIndice1')[carousel.id-1]}}</sup>
                            <span>{{$t('DataLightboxLegendSubTxt1')[carousel.id-1]}}</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="nav-lightbox">
                <a href="#" class="btn btn-white btn-nav-lightbox btn-l-prev" @click.prevent="goToprev(lightbox1)" :data-postion-active="priority" :class="{'disabled': disabled_prev}">
                    <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.799 1L10.8995 10.8995L1 1" stroke="#F3C678" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.799 1L10.8995 10.8995L1 1" stroke="#F3C678" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </a>
                <a href="#" class="btn btn-white btn-nav-lightbox btn-l-next" @click.prevent="goTonext(lightbox1)" :data-postion-active="priority" :class="{'disabled': disabled_next}">
                    <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.799 1L10.8995 10.8995L1 1" stroke="#F3C678" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.799 1L10.8995 10.8995L1 1" stroke="#F3C678" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </a>
            </div>
        </div>
        <!--LightBox Carousel 1-->

        <!--LightBox Carousel 2-->
        <div class="lightbox lightbox-2" :class="{'show': showLightbox2}">
            <a href="#" class="btn btn-white btn-close-lightbox" @click="closeLightbox2">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.635934 13.364L13.3639 0.636039M0.635934 0.636039L13.3639 13.364" stroke="#F3C678" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </a>
            <div class="lightbox-slider" v-show="showLightbox2">
                <div class="lightbox-item" v-for="carousel in carousel2" :key="carousel.id" :data-item="carousel.id">
                    <picture> 
                        <source :srcset="carousel.srcsetWebp" type="image/webp">
                        <img  :src="carousel.src" :srcset="carousel.srcset" :alt="carousel.alt" width="1920" height="937">
                    </picture>
                    <div class="legend-block">
                        <p class="legend-text">
                            <strong>{{$t('DataLightboxLegend2')[carousel.id-1]}}</strong>
                            {{$t('DataLightboxLegendSubNumber2')[carousel.id-1]}}<sup>{{$t('DataLightboxLegendSubIndice2')[carousel.id-1]}}</sup>
                            <span>{{$t('DataLightboxLegendSubTxt2')[carousel.id-1]}}</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="nav-lightbox">
                <a href="#" class="btn btn-white btn-nav-lightbox btn-l-prev" @click.prevent="goToprev(lightbox2)" :data-postion-active="priority2" :class="{'disabled': disabled_prev}">
                    <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.799 1L10.8995 10.8995L1 1" stroke="#F3C678" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.799 1L10.8995 10.8995L1 1" stroke="#F3C678" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </a>
                <a href="#" class="btn btn-white btn-nav-lightbox btn-l-next" @click.prevent="goTonext(lightbox2)" :data-postion-active="priority2" :class="{'disabled': disabled_next}">
                    <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.799 1L10.8995 10.8995L1 1" stroke="#F3C678" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.799 1L10.8995 10.8995L1 1" stroke="#F3C678" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </a>
            </div>
        </div>
        <!--LightBox Carousel 2-->

        <!--LightBox Carousel 3-->
        <div class="lightbox lightbox-3" :class="{'show': showLightbox3}">
            <a href="#" class="btn btn-white btn-close-lightbox" @click="closeLightbox3">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.635934 13.364L13.3639 0.636039M0.635934 0.636039L13.3639 13.364" stroke="#F3C678" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </a>
            <div class="lightbox-slider" v-show="showLightbox3">
                <div class="lightbox-item" v-for="carousel in carousel3" :key="carousel.id" :data-item="carousel.id">
                    <picture> 
                        <source :srcset="carousel.srcsetWebp" type="image/webp">
                        <img  :src="carousel.src" :srcset="carousel.srcset" :alt="carousel.alt">
                    </picture>
                    <div class="legend-block">
                        <p class="legend-text">
                            <strong>{{$t('DataLightboxLegend3')[carousel.id-1]}}</strong>
                            {{$t('DataLightboxLegendSubNumber3')[carousel.id-1]}}<sup>{{$t('DataLightboxLegendSubIndice3')[carousel.id-1]}}</sup>
                            <span>{{$t('DataLightboxLegendSubTxt3')[carousel.id-1]}}</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="nav-lightbox">
                <a href="#" class="btn btn-white btn-nav-lightbox btn-l-prev"  @click.prevent="goToprev(lightbox3)" :data-postion-active="priority3" :class="{'disabled': disabled_prev}">
                    <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.799 1L10.8995 10.8995L1 1" stroke="#F3C678" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.799 1L10.8995 10.8995L1 1" stroke="#F3C678" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </a>
                <a href="#" class="btn btn-white btn-nav-lightbox btn-l-next" @click.prevent="goTonext(lightbox3)" :data-postion-active="priority3" :class="{'disabled': disabled_next}">
                    <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.799 1L10.8995 10.8995L1 1" stroke="#F3C678" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <svg width="22" height="12" viewBox="0 0 22 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20.799 1L10.8995 10.8995L1 1" stroke="#F3C678" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </a>
            </div>
        </div>
        <!--LightBox Carousel 3-->
    </div>
</template>

<script>
import { Hooper, Slide, Pagination as HooperPagination } from 'hooper';
import 'hooper/dist/hooper.css';

const arrayCarousel1 = [
    {id: 1, src: "/images/composition-inclusive/carousel/Interface-Bureaux-Paris-01.jpg", srcset:"/images/composition-inclusive/carousel/Interface-Bureaux-Paris-01-retina.jpg 2x" , srcsetWebp:"/images/composition-inclusive/carousel/Interface-Bureaux-Paris-01.webp", alt: "Interface-Bureaux-Paris-01-V2-Hall-Lobby-051", legend: "Le Lobby /", legendSub: "RDC"},
    {id: 2, src: "/images/composition-inclusive/carousel/Interface-Bureaux-Paris-02.jpg", srcset:"/images/composition-inclusive/carousel/Interface-Bureaux-Paris-02-retina.jpg 2x" , srcsetWebp:"/images/composition-inclusive/carousel/Interface-Bureaux-Paris-02.webp", alt: "Interface-Bureaux-Paris-Brulerie 06_BLEU", legend: "La Plaza /", legendSub: "RDC"},
    {id: 3, src: "/images/composition-inclusive/carousel/Interface-Bureaux-Paris-03.jpg", srcset:"/images/composition-inclusive/carousel/Interface-Bureaux-Paris-03-retina.jpg 2x" , srcsetWebp:"/images/composition-inclusive/carousel/Interface-Bureaux-Paris-03.webp", alt: "Interface-Bureaux-Paris-05-V2 Scramble-hdv7", legend: "La cuisine du marché /", legendSub: "RDC" },
    {id: 4, src: "/images/composition-inclusive/carousel/Interface-Bureaux-Paris-04.jpg", srcset:"/images/composition-inclusive/carousel/Interface-Bureaux-Paris-04-retina.jpg 2x" , srcsetWebp:"/images/composition-inclusive/carousel/Interface-Bureaux-Paris-04.webp", alt: "Interface-Bureaux-Paris-07-V2-Rie-La Grande-salle-06", legend: "Le Restaurant /", legendSub: "RDC" },
    {id: 5, src: "/images/composition-inclusive/carousel/Interface-Bureaux-Paris-05.jpg", srcset:"/images/composition-inclusive/carousel/Interface-Bureaux-Paris-05-retina.jpg 2x" , srcsetWebp:"/images/composition-inclusive/carousel/Interface-Bureaux-Paris-05.webp", alt: "Interface-Bureaux-Paris-11-V2-Bureau R+1-04", legend: "Les Kiosques /", legendSub: "RDC" },
    {id: 6, src: "/images/composition-inclusive/carousel/Interface-Bureaux-Paris-06.jpg", srcset:"/images/composition-inclusive/carousel/Interface-Bureaux-Paris-06-retina.jpg 2x" , srcsetWebp:"/images/composition-inclusive/carousel/Interface-Bureaux-Paris-06.webp", alt: "Interface-Bureaux-Paris-Rooftop 07", legend: "L’auditorium /", legendSub: "R+1" },
    {id: 7, src: "/images/composition-inclusive/carousel/Interface-Bureaux-Paris-sport-8.jpg", srcset:"/images/composition-inclusive/carousel/Interface-Bureaux-Paris-sport-retina.jpg 2x" , srcsetWebp:"/images/composition-inclusive/carousel/Interface-Bureaux-Paris-sport-8.webp", alt: "Interface-Bureaux-Paris-Rooftop 8", legend: "Les Studios /", legendSub: "R+1" },
];

const arrayCarousel2 = [
    {id: 1, src: "/images/composition-inclusive/carousel/Interface-Bureaux-Paris-c3.jpg", srcset:"/images/composition-inclusive/carousel/Interface-Bureaux-Paris-c3-retina.jpg 2x", srcsetWebp:"/images/composition-inclusive/carousel/Interface-Bureaux-Paris-c3.webp", alt: "Interface-Bureaux-Paris-Brulerie 06_BLEU3", legend: "Les bureaux /", legendSub: "R+3"},
    {id: 2, src: "/images/composition-inclusive/carousel/Interface-Bureaux-Paris-c2.jpg", srcset:"/images/composition-inclusive/carousel/Interface-Bureaux-Paris-c2-retina.jpg 2x", srcsetWebp:"/images/composition-inclusive/carousel/Interface-Bureaux-Paris-c2.webp", alt: "Interface-Bureaux-Paris-Brulerie 06_BLEU2", legend: "Les bureaux /", legendSub: "R+6"},
];

const arrayCarousel3 = [
    {id: 1, src: "/images/composition-inclusive/carousel/Interface-Bureaux-Paris-d1.jpg",srcset:"/images/composition-inclusive/carousel/Interface-Bureaux-Paris-d1-retina.jpg 2x", srcsetWebp:"/images/composition-inclusive/carousel/Interface-Bureaux-Paris-d1.webp",  alt: "Interface-Bureaux-Paris-01-V2-Hall-Lobby 6", legend: "Le Rooftop /", legendSub: "R+8"},
    {id: 2, src: "/images/composition-inclusive/carousel/Interface-Bureaux-Paris-d2.jpg",srcset:"/images/composition-inclusive/carousel/Interface-Bureaux-Paris-d2-retina.jpg 2x", srcsetWebp:"/images/composition-inclusive/carousel/Interface-Bureaux-Paris-d2.webp", alt: "Interface-Bureaux-Paris-Brulerie 06_BLEU_2", legend: "Le Rooftop /", legendSub: "R+8"},
];

const contentTable = [
    {sub: "R+9", subNumber: "", pers: "", service: "" , subl: "", surfaces:""}, 
    {sub: "R+8", subNumber: "", pers: "", service: "" , subl: "", surfaces:"3 725"}, 
    {sub: "R+7", subNumber: "4 675", pers: "495", service: "1 024" , subl: "5 699", surfaces:"76"}, 
    {sub: "R+6", subNumber: "4 680", pers: "498", service: "1 036" , subl: "5 716", surfaces:"377"}, 
    {sub: "R+5", subNumber: "4 640", pers: "497", service: "1 057" , subl: "5 697", surfaces:"379"}, 
    {sub: "R+4", subNumber: "4 953", pers: "531", service: "1 118" , subl: "6 071", surfaces:"583 "}, 
    {sub: "R+3", subNumber: "4 428", pers: "582", service: "1 235" , subl: "6 662", surfaces:"58"}, 
    {sub: "R+2", subNumber: "5 549", pers: "588", service: "1 258" , subl: "6 807", surfaces:"57"}, 
    {sub: "R+1", subNumber: "5 510", pers: "588", service: "1 266" , subl: "6 775", surfaces:"581"}, 
    {sub: "RDC/Mezz",subNumber:"", pers: "17", service: "" , subl: "", surfaces:"1 378"}, 
];


export default {
  name: 'Compositionmobile',
  components: {
    Hooper,
    Slide,
    HooperPagination,
    VideoCompositionInclusive: () => import('@/components/video/VideoCompositionInclusive.vue'),
  },
  props: {
    msg: String
  },
  metaInfo() {
      return { 
          title: "Rhapsody - Composition inclusive",
          meta: [
              { name: 'description', content:  'Rhapsody, de l’esquisse au projet. Retrouvez les différents espaces au sien de RHAPSODY, en passant par le lobby jusqu’au rooftop de 3700 m2.'},
              { property: 'og:title', content: "Rhapsody - Composition inclusive"},
              { property: 'og:site_name', content: 'Rhapsody'},
              { property: 'og:type', content: 'website'},    
          ]
      }
  },
  data(){
    return{
    tableVivre: contentTable,
        scroll: null,
        carousel1: arrayCarousel1,
        carousel2: arrayCarousel2,
        carousel3: arrayCarousel3,
        incrementE: false,
        increment: false,
        showLightbox:false,
        showLightbox2:false,
        showLightbox3:false,
        showBrushK:false,
        showBrush:false,
        lightbox1: ".lightbox-1",
        lightbox2: ".lightbox-2",
        lightbox3: ".lightbox-3",
        priority:1,
        priority2:1,
        priority3:1,
        disabled_next: false,
        disabled_prev: false,
        settings: {
            "dots": true,
            "infinite": false,
            "arrows":false,
            "centerMode": true,
            "vertical" : true,
            "slidesToShow": 1,
            "slidesToScroll": 1,
            "initialSlide": 0,
            "centerPadding": "100px"
            },

            hooperSettings: {
                itemsToShow: 1.25,
                centerMode: true,
                vertical: true,
                breakpoints: {
                320: {
                    centerMode: false,
                    itemsToShow: 3
                },
                992: {
                    itemsToShow: 1.25,
                    pagination: 'fraction'
                }
            }
        },
        AnimtxtBlur: false,
        showBloc: false,
    }
  },
  created () {
    window.addEventListener('wheel', this.handleWheel);
  },
  destroyed () {
    window.removeEventListener('wheel', this.handleWheel);
  },
  methods:{
        theFormat(number) {
        return this.numberWithSpaces(number.toFixed(0));
        },

        theFormatDecimal(number) {
        return number.toFixed(2);
        },
        
        playAnimationRassemebleurs() {
            this.$refs.number1.play()
            this.$refs.number2.play()
            this.$refs.number3.play()
            this.$refs.number4.play()
        },

        playAnimationPoll() {
            this.$refs.number5.play()
            this.$refs.number6.play()
            this.$refs.number7.play()
            this.$refs.number8.play()
            this.$refs.number9.play()
            this.$refs.number10.play()
        },

      /*fonction get data number element*/
       getDataNumberElement(itemLightBox){
            let idr = 0;
            for (var i = 0, len = itemLightBox.length; i < len; i++) {
                if(itemLightBox[i].classList.contains('active')){
                    idr = parseInt(itemLightBox[i].getAttribute('data-item'));
                }
            }
            return idr;
       },

        scrollToNext(e){
            e.preventDefault();
            var container = this.$el.querySelector(".section-image");
            container.scrollTop = container.scrollHeight;
            container.scrollIntoView({behavior: 'smooth'});
        },

        /*************************/
        /*   GESTION LIGHTBOX  */
        /*************************/
        /*Prev lightbox item*/
        goToprev(lightbox){
            let itemLightBox = document.querySelectorAll(lightbox + ' .lightbox-item');
            let nbitem = itemLightBox.length;
            let idActuel = this.getDataNumberElement(itemLightBox);
            
            if(idActuel <= nbitem && idActuel > 1){
                itemLightBox[idActuel - 1].classList.remove('active');
                itemLightBox[idActuel - 2].classList.add('active');
                this.priority = idActuel - 1;
            }

            this.disabled_prev = (idActuel-1)===1;
            this.disabled_next = (idActuel-1)===nbitem;
        },

        /*Next lightbox item*/
        goTonext(lightbox){
            let itemLightBox = document.querySelectorAll(lightbox + ' .lightbox-item');
            let nbitem = itemLightBox.length;
            let idActuel = this.getDataNumberElement(itemLightBox);
            if(idActuel < nbitem){
                itemLightBox[idActuel - 1].classList.remove('active');
                itemLightBox[idActuel].classList.add('active');
                this.priority = idActuel + 1;
            }
            
            this.disabled_next = (idActuel+1)===nbitem;
            this.disabled_prev = (idActuel+1)===1;
        },

        /*Fonction openLightBox*/
        openLightbox(id, lightbox){
            this.showLightbox = true;
            document.body.classList.add('open-menu');
            let itemLightBox = document.querySelectorAll('.lightbox-item');
            for (var i = 0, len = itemLightBox.length; i < len; i++) {
                if(parseInt(itemLightBox[i].getAttribute('data-item')) == id){
                    itemLightBox[i].classList.add('active');
                    document.querySelector('.btn-l-prev').setAttribute('data-postion-active',id); 
                    document.querySelector('.btn-l-next').setAttribute('data-postion-active',id);
                    this.priority = id;
                }
            }

            /**** CHECK BTN PREV */
            let check_LightBox = document.querySelectorAll(lightbox + ' .lightbox-item');
            let nbitem = check_LightBox.length;
            let idActuel = this.getDataNumberElement(check_LightBox);
            
            this.disabled_prev = (idActuel)===1;
            this.disabled_next = (idActuel)===nbitem;
        },

        /*Fonction closeLightbox*/
        closeLightbox(e){
            e.preventDefault();
            this.showLightbox = false;
            document.body.classList.remove('open-menu');
            let itemLightBox = document.querySelectorAll('.lightbox-item');
            for (var i = 0, len = itemLightBox.length; i < len; i++) {
                itemLightBox[i].classList.remove('active'); 
            }
            this.priority = 1;
        },

        /*Fonction openLightBox*/
        openLightbox2(id, lightbox){
            this.showLightbox2 = true;
            document.body.classList.add('open-menu');
            let itemLightBox = document.querySelectorAll('.lightbox-item');
            for (var i = 0, len = itemLightBox.length; i < len; i++) {
                if(parseInt(itemLightBox[i].getAttribute('data-item')) == id){
                    itemLightBox[i].classList.add('active');
                    document.querySelector('.btn-l-prev').setAttribute('data-postion-active',id); 
                    document.querySelector('.btn-l-next').setAttribute('data-postion-active',id);
                    this.priority2 = id;
                }
            }
            /**** CHECK BTN PREV */
            let check_LightBox = document.querySelectorAll(lightbox + ' .lightbox-item');
            let nbitem = check_LightBox.length;
            let idActuel = this.getDataNumberElement(check_LightBox);
            
            this.disabled_prev = (idActuel)===1;
            this.disabled_next = (idActuel)===nbitem;
        },

        /*Fonction closeLightbox*/
        closeLightbox2(e){
            e.preventDefault();
            this.showLightbox2 = false;
            document.body.classList.remove('open-menu');
            let itemLightBox = document.querySelectorAll('.lightbox-item');
            for (var i = 0, len = itemLightBox.length; i < len; i++) {
                itemLightBox[i].classList.remove('active'); 
            }
            this.priority2 = 1;
        },

        /*Fonction openLightBox*/
        openLightbox3(id, lightbox){
            this.showLightbox3 = true;
            document.body.classList.add('open-menu');
            let itemLightBox = document.querySelectorAll('.lightbox-item');
            for (var i = 0, len = itemLightBox.length; i < len; i++) {
                if(parseInt(itemLightBox[i].getAttribute('data-item')) == id){
                    itemLightBox[i].classList.add('active');
                    document.querySelector('.btn-l-prev').setAttribute('data-postion-active',id); 
                    document.querySelector('.btn-l-next').setAttribute('data-postion-active',id);
                    this.priority3 = id;
                }
            }
            /**** CHECK BTN PREV */
            let check_LightBox = document.querySelectorAll(lightbox + ' .lightbox-item');
            let nbitem = check_LightBox.length;
            let idActuel = this.getDataNumberElement(check_LightBox);
            
            this.disabled_prev = (idActuel)===1;
            this.disabled_next = (idActuel)===nbitem;
        },

        /*Fonction closeLightbox*/
        closeLightbox3(e){
            e.preventDefault();
            this.showLightbox3 = false;
            document.body.classList.remove('open-menu');
            let itemLightBox = document.querySelectorAll('.lightbox-item');
            for (var i = 0, len = itemLightBox.length; i < len; i++) {
                itemLightBox[i].classList.remove('active'); 
            }
            this.priority3 = 1;
        },

        stopBlockPropagation(e){
            e.stopPropagation();
        },

        wheelIntersection(e){
            e.stopPropagation();
            let keyNumberBlock = document.querySelector('.page-section-scrollable');
            this.newValue = document.querySelector('.page-section-scrollable').scrollTop;
            if (this.oldValue > this.newValue) {
                if(keyNumberBlock.scrollTop === 0){
                    document.querySelector('.hooper-prev').click();
                }

            } else if (this.oldValue < this.newValue) {
                
                if(keyNumberBlock.scrollTop === (keyNumberBlock.scrollHeight - keyNumberBlock.offsetHeight)){
                    document.querySelector('.hooper-next').click();
                }
                else{
                    this.stopBlockPropagation(e);
                }
            }
            this.oldValue = this.newValue;
        },

        wheelTouch(){
            const el = document.querySelector('.page-composition-inclusive');
            const el2P = document.querySelector('.section-espaces-rassembleurs');
            const dnF = document.querySelector('.section-espaces-rassembleurs .dynamique-images-flou');
            const el2KN = document.querySelector('.section-espaces-rassembleurs .block-key-number');
            // const elDe = document.querySelector('.section-destination');
            const elType = document.querySelector('.section-poste-resident');
            const el3P = document.querySelector('.section-espace-pollinisateur');
            const vnF = document.querySelector('.section-espace-pollinisateur .dynamique-images-flou');
            const el3KN = document.querySelector('.section-espace-pollinisateur .block-key-number');
            const rnF = document.querySelector('.section-rooftop .dynamique-images-flou');
            if(this.increment == false){
                if(el.scrollLeft >=  (el2P.offsetLeft + el2KN.offsetLeft - 200)){
                    this.playAnimationRassemebleurs();
                    this.increment = true;
                }
            }
            else if(this.incrementE == false){
                if(el.scrollLeft >=  (el3P.offsetLeft + el3KN.offsetLeft - 200)){
                    this.playAnimationPoll()
                    this.incrementE = true;
                }
            }
            if(el.scrollLeft > 0 && el.scrollLeft < el3P.offsetLeft - 1300){
                dnF.classList.add('showBefore');
                vnF.classList.remove('showBefore');
                rnF.classList.remove('showBefore');
            }
            
            else if((el.scrollLeft > el3P.offsetLeft - 1300) && (el.scrollLeft < elType.offsetLeft)){
                dnF.classList.remove('showBefore');
                vnF.classList.add('showBefore');
                rnF.classList.remove('showBefore');
            }
            else if(el.scrollLeft > elType.offsetLeft){
                 dnF.classList.remove('showBefore');
                 vnF.classList.remove('showBefore');
                 rnF.classList.add('showBefore');
            }
        },

        addFormatNumber(el){
            let item = document.querySelectorAll(el);
            for(let i = 0; i < item.length; i++){
                item[i].innerText = this.numberWithSpaces(item[i].innerText);
        }
    },

    numberWithSpaces(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },

    lazyLoading(){
        /*Diffère tous les images*/
        setTimeout(function(){
            let loadImgSource = document.querySelectorAll('.loadTemp source');
            let loadImgImg = document.querySelectorAll('.loadTemp img');
            for(let i = 0; i < loadImgSource.length; i++){
                loadImgSource[i].setAttribute('srcset',loadImgSource[i].getAttribute('dta-srcset'));
                loadImgImg[i].setAttribute('src',loadImgImg[i].getAttribute('dta-src'));
                loadImgImg[i].setAttribute('srcset',loadImgImg[i].getAttribute('dta-srcset'));
            }
        }.bind(this), 1200);
    }   
  },

  mounted(){
    document.body.classList.remove('removeHiddenOverflow');
    setTimeout(() => {
        this.AnimtxtBlur = true;
        this.showBloc = true
    }, 3000);
    setTimeout(() => {
        document.querySelector(".section-first .bg-hero").play()
    }, 1000);

    this.lazyLoading()
  }
}
</script>

